const arrangePopupProps = {
    
"demo-schedule-popup": ["developer", "hirer"],
    
"chat-with-us": ["now", "later"],
    
"explore-geektastic-hiring-partner-marketplace-popup": ["developer", "hirer"],
    
    
"arrange-demo-popup": [],
    
};

function arrangeDemoPopUp(id, step, type, step2Only) {
    'use strict';

    if (id === "arrange-demo-popup") {
        arrangeDatacapture.submitButtonText();
    }

    function showHide(classStep, addRemove) {
        Array.from(document.getElementById(id).getElementsByClassName("step-" + classStep)).
            forEach(
                function (elem) {
                    elem.classList[addRemove]("dialog--hidden");
                });
    }

    switch (step) {
        case (1): {
            showHide("1", "remove");
            showHide("2", "add");
            document.getElementById(id).classList.remove("dialog--step-2");
            break;
        }
        case (2): {
            showHide("1", "add");
            showHide("2", "add");

            if (step2Only === true) {
                showHide("2--only", "remove");
                showHide("2--only-removed", "add");
            } else {
                showHide("2--back", "remove");
            }

            showHide("2--" + type, "remove");
            document.getElementById(id).classList.add("dialog--step-2");
            break;
        }
    }
}

function arrangeHashCheck(hash) {
    'use strict';

    if (hash == "#close" || hash == "") {
        aria.OpenDialogList.forEach((d) => arrangeCloseDialogNoHashChange(d.dialogNode.id));
    }

    for (const id in arrangePopupProps) {
        const demoHash = "#" + id;
        if (hash.startsWith(demoHash)) {
            aria.OpenDialogList.forEach((d) => arrangeCloseDialogNoHashChange(d.dialogNode.id));

            window.openDialog(id, document.activeElement);

            document.getElementById("root").classList.add("page--inactive");

            const type = hash.substring(demoHash.length + 1);

            if (type.length > 1 &&
                arrangePopupProps[id].includes(type)) {
                arrangeDemoPopUp(id, 2, type, true);
            } else {
                arrangeDemoPopUp(id, 1);
            }
            break;
        }
    }
}

function arrangeCloseDialogNoHashChange(id) {
    'use strict';
    document.getElementById("root").classList.remove("page--inactive");
    if (id === "arrange-demo-popup") {
        arrangeDatacapture.resetModal();
    }
    window.closeDialog(document.getElementById(id + "__button--close"));
}

function closeDialogEvent(id) {
    'use strict';

    arrangeCloseDialogNoHashChange(id);
    window.location.hash = "close";
}

for (const id in arrangePopupProps) {

    const one = document.getElementById(id + "__button--option-1");
    if (one) {
        one.addEventListener('click',
            function () {
                arrangeDemoPopUp(id, 2, arrangePopupProps[id][0]);
            });
    }

    const two = document.getElementById(id + "__button--option-2");
    if (two) {
       two.addEventListener('click',
            function () {
                arrangeDemoPopUp(id, 2, arrangePopupProps[id][1]);
            });
    }

    const three = document.getElementById(id + "__button--back");
    if (three) {
        three.addEventListener('click',
            function () {
                arrangeDemoPopUp(id, 1);
            });
    }

    // close always present
    document.getElementById(id + "__button--close").
        addEventListener('click', function () { closeDialogEvent(id); });
}

function escPressed(event) {
    const key = event.which || event.keyCode;

    if (key === aria.KeyCode.ESC) {
        document.getElementById("root").classList.remove("page--inactive");
        window.location.hash = "close";
    }
};

window.addEventListener("hashchange", function (e) { arrangeHashCheck(e.target.location.hash); }, false);
document.addEventListener('keyup', escPressed);

scriptSync("aria-dialog", () => arrangeHashCheck(window.location.hash));